/**
 * Input Schema of PlatformType
 * @author Simpli CLI generator
 */
import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldSet, FieldComponent} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-input'
import {PlatformType} from '@/model/resource/PlatformType'

/* TODO: review generated schema */
export class InputPlatformTypeSchema extends DefaultSchema {
  readonly name = 'InputPlatformType'

  readonly fieldSet: FieldSet<PlatformType> = {
    title: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'text',
        maxlength: 63,
        label: this.translateFrom(schema.fieldName),
        required: true,
        validation: 'required',
        class: 'col-span-2',
      },
    }),
    isMobile: (schema): FieldComponent => ({
      is: Component.InputCheckbox,
      bind: {
        label: this.translateFrom(schema.fieldName),
      },
    }),
    isDesktop: (schema): FieldComponent => ({
      is: Component.InputCheckbox,
      bind: {
        label: this.translateFrom(schema.fieldName),
      },
    }),
    isWeb: (schema): FieldComponent => ({
      is: Component.InputCheckbox,
      bind: {
        label: this.translateFrom(schema.fieldName),
      },
    }),
    isHardware: (schema): FieldComponent => ({
      is: Component.InputCheckbox,
      bind: {
        label: this.translateFrom(schema.fieldName),
      },
    }),
  }
}
