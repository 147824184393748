



































import {Component, Prop, Watch, Provide, Vue} from 'vue-property-decorator'
import {PlatformType} from '@/model/resource/PlatformType'
import {InputPlatformTypeSchema} from '@/schema/resource/PlatformType/InputPlatformTypeSchema'

@Component
export default class PersistPlatformTypeView extends Vue {
  @Prop() id?: string

  @Provide('validator') validator = this.$validator

  schema = new InputPlatformTypeSchema()
  platformType = new PlatformType()

  async created() {
    await this.populate()
  }

  async populate() {
    const id = Number(this.id) || null

    if (id) {
      await this.platformType.getPlatformType(id)
    }

    this.$await.done('getPlatformType')
  }

  async persist() {
    const isValid = await this.validator.validateAll()

    if (!isValid) {
      this.$toast.abort('system.error.validation')
    }

    await this.platformType.persistPlatformType()
    this.$toast.success('system.success.persist')
    await this.$nav.push('/platform-type/list')
  }
}
